import { navigate } from "vike/client/router";
import toast from "react-hot-toast";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

import api from "@/api/axios";
import { errorMessageExtractor } from "@/helpers/error-message";
import { Link } from "@/components/Link";
import loginImage from "@/assets/images/login-image.svg";
import Logo from "@/components/Logo";
import { useChord } from "@/hooks/useChord";
import { useUser } from "@/hooks/useUser";

import AuthWithGoogle from "../AuthWithGoogle";

import type { ErrorResponse } from "@/types/error";

export default function Login() {
  const chord = useChord();
  const { user } = useUser();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [honeypot, setHoneypot] = useState("");
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      if (user?.id) {
        navigate("/feed");
      } else {
        setLoading(false);
      }
    };

    checkAuth();
  }, [user?.id]);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    if (honeypot) {
      toast.error("Invalid submission.", {
        duration: 3000,
        position: "bottom-right",
        style: {
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }

    try {
      const { data } = await api.post("/v1/users/sign_in", { email, password });
      if (data.status === "success") {
        chord.identify({ email });
        chord.trackSignedIn({ email, method: "email" });
        navigate("/feed");
      }
    } catch (e) {
      const { message } = errorMessageExtractor(e as ErrorResponse);

      toast.error(message, {
        duration: 10000,
      });
    }
  }

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <span className="loading loading-spinner loading-lg text-primary"></span>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-primary/10 to-primary/20 flex justify-center">
      <div className="w-full max-w-md">
        <div className="flex justify-center items-center text-primary mt-6 mb-8">
          <Logo />
        </div>
        <div className="relative z-10 flex justify-center items-center">
          <img src={loginImage} alt="Login Image" className="w-[340px]" />
        </div>

        <div className="card bg-base-100 relative -top-4 px-3 mx-3 rounded-2xl">
          <div className="card-body">
            <form id="login-form" name="login-form" onSubmit={handleSubmit}>
              {/* Honeypot */}
              <input
                type="text"
                name="website"
                value={honeypot}
                onChange={(e) => setHoneypot(e.target.value)}
                style={{
                  display: "none", // Hide it with CSS
                  position: "absolute",
                  left: "-9999px", // Move it off-screen
                }}
                tabIndex={-1} // Prevent keyboard navigation to this field
                autoComplete="off" // Prevent autofill
                aria-hidden="true" // Hide from screen readers
              />
              <div className="form-control mb-3">
                <input
                  placeholder="Email"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="input input-bordered"
                />
              </div>

              <div className="form-control mb-5">
                <div className="relative">
                  <input
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="input input-bordered w-full"
                  />
                  <button
                    type="button"
                    tabIndex={-1}
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 -translate-y-1/2"
                  >
                    {showPassword ? (
                      <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                    ) : (
                      <EyeIcon className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                </div>
              </div>

              <div className="form-control my-3">
                <button type="submit" className="btn btn-primary text-base">
                  Sign In
                </button>
              </div>

              <AuthWithGoogle mode="login" />

              <p className="text-center mt-4">
                <Link to="/forgot-password" className="link link-primary text-base">
                  Forgot your password?
                </Link>
              </p>

              <div className="divider">OR</div>

              <div className="flex flex-col items-center gap-2 mt-4">
                <Link to="/signup" className="btn btn-secondary w-full text-base">
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
